<template>
  <div class="notice">
    <cms-header></cms-header>
    <!--面包屑-->
    <div class="width_width child_bg_1">
      <div class="width_w clearfix">
        <div class="now_add left clearfix">
          <router-link class="now_a left" to="/">首页</router-link>
          <img class="now_arrow left" src="../../assets/image/pc/jiantou.svg"/>
          <router-link to="/help">
            <span class="now_a left">帮助中心</span>
          </router-link>
        </div>
      </div>
      <span class="child_name">帮助中心</span>
    </div>
    <div class="cms-container notice-info">
      <div class="notice-left left">
        <ul class="tab_con clearfix">
          <li v-for="(item,index) in introductionTypeList" :key="'help'+index">
            <a :class="activeClass == index ? 'tab_active':''" class="tab_li"
               @click="getItem(index,item,'activeClass')">{{ item.categoryTitle }}</a>
          </li>
        </ul>
        <div class="help_con">
          <router-link class="tender_a line_a clearfix" v-for="(item,index) in introductionList" :key="'helpDe'+index"
                       :to="{'name':'helpDetails','query':{'id':item.id}}">
            <span class="left tender_span">{{ item.contentTitle }}</span>
            <span class="right tender_time">{{ formatDate(item.contentDatetime, 'YYYY/MM/DD') }}</span>
          </router-link>
        </div>
        <div class="cms-box" style="display:flex;justify-content: center;align-items: center;width: 100%">
          <el-pagination :current-page="currentPage" :page-size="pageSize" :total="total"
                         @current-change="handleCurrentChange" background layout="prev, pager, next">
          </el-pagination>
        </div>
      </div>
      <div class="notice-right right cms-sm-hidden cms-ssm-hidden cms-md-hidden">
        <div class="child_tool">
          <toolbar/>
          <!--联系卡片-->
          <contact-card/>
        </div>
      </div>
    </div>
    <cms-foot class="left"></cms-foot>
  </div>
</template>

<script>
import ContactCard from "../../components/commoon/component/contact-card";
import CmsHeader from "../../components/commoon/component/cms-header";
import CmsFoot from "../new-home/cms-foot";
import Toolbar from "../../components/commoon/component/toolbar";

export default {
  name: "helpDetails",
  components: {Toolbar, CmsFoot, CmsHeader, ContactCard},
  data() {
    return {
      introductionTypeList: [],
      introductionList: [],
      content: {},
      activeClass: 0,
      activeClass1: 0,
      activeClass2: 0,
      total: 0,
      currentPage: 0,
      pageSize: 10,
      pages: 0,
      cmsRouter: this.$router
    }
  }, methods: {
    /*选中按钮*/
    getItem: function (index, item, activeClassName) {
      this[activeClassName] = index;
      this.getContentList(item);
      let json = {'path': '/help', 'query': {'index': index}};
      this.$router.push(json);
    },
    getParameter: function (paraName) {
      let url = document.location.toString();
      if (url.lastIndexOf('#') == (url.length - 1)) {
        url = url.substring(0, url.lastIndexOf('#'));
      }
      let arrObj = url.split("?");
      if (arrObj.length > 1) {
        let arrPara = arrObj[1].split("&");
        let arr;
        for (let i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");
          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },
    /*获取文章列表*/
    getContentList: function (item) {
      let that = this;
      that.$http.post(that.manager + "/content/page.do", {
        page: {
          current: that.currentPage,
          size: that.pageSize
        },
        cmsContentDTO: {
          contentCategoryId: item.id
        }
      }).then(function (res) {
        that.introductionList = res.data.result.records;
        that.total = res.data.result.total;
        that.pages = res.data.result.pages;
      }).catch(function (err) {

      });
    },
    /*获取文章栏目*/
    selectContent: function (modelId, entityName, index, id) {
      let that = this;
      that.$http.get(that.base + "/category/list.do", {
        params: {
          modelId: modelId
        }
      }).then(function (data) {
        that[entityName] = data.data.result;
        if (id != null) {
          for (let i = 0; i < data.data.result.length; i++) {
            if (id == data.data.result[i].id) {
              that.getContentList(data.data.result[i])
              that.activeClass = i
            }
          }
        } else {
          that.getContentList(data.data.result[index]);
        }
      }).catch(function (error) {

      })
    },
    handleCurrentChange: function (val) {
      this.currentPage = val;
      //获取当前选择的type
      let item = {};
      if(this.$route.query.index != undefined && this.$route.query.index != null && this.$route.query.index != ""){
        item = this.introductionTypeList[this.$route.query.index];
      }else {
        item = this.introductionTypeList[0];
      }
      this.getContentList(item);
    },
    /*文章详情*/
    contentDetails: function (item) {
      /*文章详情*/
      let json = {'path': '/helpDetails', 'query': {'id': item.id}};
      this.$router.push(json);
    },
    formatDate: function (de, fmt) {
      return this.$moment(de).format(fmt);
    }
  }, created() {
    this.activeClass = this.getParameter("index");
    if (this.activeClass == null || this.activeClass == "") {
      this.activeClass = 0;
    }
    this.selectContent("introduction_type", "introductionTypeList", this.activeClass);
    document.title = this.title;
  }, watch: {
    $route(to, from) {
      let that = this;
      /*监听路由的状态*/
      let itemId = this.$router.currentRoute.query.itemId;
      if (this.$router.currentRoute.name == from.name && itemId != '' && itemId != null && itemId != "") {
        let that = this;
        this.activeClass = this.$router.currentRoute.query.index;
        that.$http.post(that.manager + "/content/page.do", {
          page: {
            current: that.currentPage,
            size: that.pageSize
          },
          cmsContentDTO: {
            contentCategoryId: this.$router.currentRoute.query.itemId
          }
        }).then(function (res) {
          that.introductionList = res.data.result.records;
          that.total = res.data.result.total;
          that.pages = res.data.result.pages;
        }).catch(function (err) {
        });
      }
    }
  },

}
</script>
<style>
.cms-box .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #D0021B;
}
</style>

<style scoped lang="css" src="../../assets/commoon/css/common.css"></style>
